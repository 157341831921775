<template>
  <div class="vue-daterange-picker position-relative d-inline-block">
    <t-button variant="light" class="border-0 dropdown-toggle py-3 px-4" :disabled="disabled" @click="togglePicker(true)">
      <template v-if="!dateRange.start">
        <span class="font-italic">
          Select A Date
          <template v-if="!singleDatePicker">
            Range
          </template>
        </span>
      </template>
      <template v-else>
        {{friendlyDate(dateRange.start)}}
        <template v-if="!singleDatePicker">
          - {{friendlyDate(dateRange.end)}}
        </template>
        <template v-if="showShortcut && dateRange.shortcut && !dateRange.shortcut.includes('CUSTOM')">
          ({{dateRange.shortcut}})
        </template>
      </template>
    </t-button>
    <transition name="slide-fade" mode="out-in">
      <div v-if="open"
           id="date-range-picker-calendar-popup"
           class="daterangepicker ltr"
           :class="pickerStyles"
           tabindex="0"
           @blur="clickAway">
        <div class="calendars d-flex">
          <!--
            Allows you to change the range

            @param {Date} startDate - current startDate
            @param {Date} endDate - current endDate
            @param {object} ranges - object with ranges
          -->
          <div v-if="ranges && Object.keys(ranges).length > 0" class="d-flex flex-column border-right w-auto p-4" style="min-width: 200px;">
            <div class="d-flex-center p-3">
              <span class="font-weight-bold">Date Ranges</span>
              <t-button-inline v-if="customRangeKey" class="ml-auto" @click="customRangeCreate = true" v-p-tooltip.top="'Create your own relative date range'">
                <fluency-icon type="add"></fluency-icon>
                Add
              </t-button-inline>
            </div>
            <slot name="ranges"
                  :startDate="start"
                  :endDate="end"
                  :ranges="combinedRanges">
              <calendar-ranges @clickRange="clickRange($event)"
                               @edit="editCustomRange($event)"
                               :ranges="combinedRanges"
                               ref="calendarRanges"
                               style="max-height: 300px; overflow-y: auto;"
                               :selected="{ startDate: start, endDate: end }" />
            </slot>
            <div v-if="!noCompare" class="pt-4 px-3 mt-auto d-flex-center"
                 v-p-tooltip.top="'Compares statistics with a matching previous span. Shows previous metrics on relevant tables.'">
              <div class="mr-3 mt-3">Compare</div>
              <b-checkbox :checked="compare"
                          switch
                          @change="handleCompare($event)">
              </b-checkbox>
            </div>
          </div>

          <div class="d-flex flex-column p-4" :class="{single: singleDatePicker}">

            <template v-if="customRangeCreate">
              <div class="d-flex flex-column flex-grow-1 pl-5" style="width: 479px;">
                <div class="font-weight-bold pt-3 pb-5">{{customRangeEdit ? 'Edit' : 'New'}} Custom Date Range</div>
                <div class="d-flex-center mb-4">
                  <div class="w-50">
                    <label class="mb-0">Show the Last</label>
                    <div class="mad-lib-container">
                      <basic-mad-lib-input v-model="customRange.lastXDays" subtle format="NUMBER"></basic-mad-lib-input>
                      <span class="ml-3">Days</span>
                    </div>
                  </div>
                  <div class="w-50">
                    <label class="mb-0">Starting From</label>
                    <div class="mad-lib-container">
                      <basic-mad-lib-input v-model="customRange.offset" subtle format="NUMBER"></basic-mad-lib-input>
                      <span class="ml-3">Days Ago</span>
                    </div>
                  </div>
                </div>
                <div class="mb-5">
                  <label>Preview Title</label>
                  <div class="text-black-50" v-if="customRange.lastXDays !== null">{{customRangeLabelPreview}}</div>
                  <div v-else class="text-black-50 font-italic">Nothing to Preview Yet</div>
                </div>
                <div class="mb-5">
                  <label>Preview Date Range</label>
                  <div class="text-black-50" v-if="customRange.lastXDays !== null">{{customRangePreview}}</div>
                  <div v-else class="text-black-50 font-italic">Nothing to Preview Yet</div>
                </div>
                <div class="mt-auto d-flex justify-content-end">
                  <t-button v-if="customRangeEdit" variant="outline-danger" class="mr-auto" @click="deleteCustomRange(customRangeEdit)">Delete</t-button>
                  <t-button class="mr-2" @click="cancelCustomRange()">Cancel</t-button>
                  <t-button variant="primary" :disabled="!customRange.lastXDays" @click="createCustomRange(!!customRangeEdit)">{{customRangeEdit ? 'Edit' : 'Create'}} Custom Range</t-button>
                </div>
              </div>

            </template>

            <div v-else class="d-flex">
              <div class="daterangepicker_input d-none d-sm-block" v-if="false">
                <input class="input-mini form-control" type="text" name="daterangepicker_start"
                       :value="startText"/>
                <i class="fa fa-calendar glyphicon glyphicon-calendar"></i>
              </div>
              <div class="drp-calendar">
                <div class="calendar-table">
                  <calendar :monthDate="monthDate"
                            :locale-data="locale"
                            :start="start" :end="end"
                            :minDate="min" :maxDate="max"
                            :show-dropdowns="showDropdowns"
                            @change-month="changeLeftMonth"
                            :date-format="dateFormatFn"
                            @dateClick="dateClick" @hoverDate="hoverDate"
                            :showWeekNumbers="showWeekNumbers" />
                </div>
                <calendar-time v-if="timePicker"
                               @update="onUpdateStartTime"
                               :miniute-increment="timePickerIncrement"
                               :hour24="timePicker24Hour"
                               :second-picker="timePickerSeconds"
                               :current-time="start || undefined" />
              </div>

              <div class="drp-calendar ml-4 pl-4 border-left" v-if="!singleDatePicker">
                <div class="calendar-table">
                  <calendar :monthDate="nextMonthDate"
                            :locale-data="locale"
                            :start="start" :end="end"
                            :minDate="min" :maxDate="max"
                            :show-dropdowns="showDropdowns"

                            @change-month="changeRightMonth"
                            :date-format="dateFormatFn"

                            @dateClick="dateClick" @hoverDate="hoverDate"
                            :showWeekNumbers="showWeekNumbers" />
                </div>
                <calendar-time v-if="timePicker"
                               @update="onUpdateEndTime"
                               :miniute-increment="timePickerIncrement"
                               :hour24="timePicker24Hour"
                               :second-picker="timePickerSeconds"
                               :current-time="end || undefined" />
              </div>
            </div>
            <div v-if="!singleDatePicker && !customRangeCreate" class="d-flex mt-4">
              <div style="width: 150px;">
                <label class="mb-0">Title</label>
                <div class="mad-lib-container">
                  <div v-if="shortcut" class="text-black-50">{{shortcut.includes('CUSTOM') ? labelCustom(shortcut) : shortcut}}</div>
                  <div v-else-if="start && end" class="text-black-50">Custom Fixed Range</div>
                  <div v-else class="text-black-50">Nothing Selected</div>
                </div>
              </div>
              <div>
                <label class="mb-0">Date Range</label>
                <div class="mad-lib-container">
                  <basic-mad-lib-input :value="start ? slashDate(start) : undefined" @input="manualEntry('start', $event)"></basic-mad-lib-input>
                  <span class="mx-3">-</span>
                  <basic-mad-lib-input :value="end ? slashDate(end) : undefined" @input="manualEntry('end', $event)"></basic-mad-lib-input>
                </div>
              </div>
            </div>
            <div v-if="!noCompare && !customRangeCreate" class="d-flex w-100 mt-4">
              <div style="width: 150px">
                <template v-if="compare && showCompareStrategies">
                  <label class="mb-0">Compare Strategy</label>
                  <div class="mad-lib-container">
                    <mad-lib-select :value="priorDateStrategy" :options="compareStrategies" @input="handleCompareStrategyChange($event)">
                      <template #dropdown-item-content="{ text, option }">
                        <div>{{text}}</div>
                        <small class="mt-2" style="max-width: 160px;">{{option.description}}</small>
                      </template>
                    </mad-lib-select>
                  </div>
                </template>
              </div>
              <div v-if="compare && compareOverride">
                <label class="mb-0">Compare Range</label>
                <div class="mad-lib-container">
                  <template v-if="compareStart">
                    <basic-mad-lib-input :value="compareStartOverride || compareStart" @input="manualCompareEntry(true, $event)" />
                  </template>
                  <template v-if="compareEnd">
                    <span class="mx-3">-</span>
                    <basic-mad-lib-input :value="compareEndOverride ||  compareEnd" @input="manualCompareEntry(false, $event)" />
                  </template>
                </div>
              </div>
            </div>
            <div v-if="!customRangeCreate" class="d-flex align-items-center justify-content-end mt-auto pt-4">
              <t-button v-if="showClearButton" class="date-picker-footer-cta font-weight-normal mr-2" @click="clearDates()">Clear</t-button>
              <t-button class="date-picker-footer-cta font-weight-normal"
                        variant="primary"
                        :disabled="in_selection && (startAndEnd.start !== startAndEnd.end) || customRangeCreate"
                        @click="clickedApply()">Apply</t-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import _isEqual from 'lodash.isequal'
import Calendar from './Calendar.vue'
import CalendarTime from './CalendarTime.vue'
import CalendarRanges from './CalendarRanges.vue'
import MadLibSelect from 'core-ui/components/inputs/madLibSelect.vue'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput.vue'
import { nextMonth, prevMonth, validateDateRange, yearMonth } from './util'
import dateShortcuts from '../../../../assets/js/dateShortcuts'
import moment from 'moment'

export default {
  name: 'dateRangePicker',
  inheritAttrs: false,
  components: { Calendar, CalendarTime, CalendarRanges, MadLibSelect, BasicMadLibInput },
  props: {
    /**
     * minimum date allowed to be selected
     * @default null
     */
    minDate: {
      type: [String, Date],
      default () {
        return null
      }
    },
    /**
     * maximum date allowed to be selected
     * @default null
     */
    maxDate: {
      type: [String, Date],
      default () {
        return null
      }
    },
    /**
     * Show the week numbers on the left side of the calendar
     */
    showWeekNumbers: {
      type: Boolean,
      default: false
    },
    /**
     * Each calendar has separate navigation when this is false
     */
    linkedCalendars: {
      type: Boolean,
      default: true
    },
    /**
     * Allows you to select only one date (instead of range). This will hide the ranges with different start/end
     */
    singleDatePicker: {
      type: Boolean,
      default: false
    },
    /**
     * Show the dropdowns for month and year selection above the calendars
     */
    showDropdowns: {
      type: Boolean,
      default: false
    },
    /**
     * Show the dropdowns for time (hour/minute) selection below the calendars
     */
    timePicker: {
      type: Boolean,
      default: false
    },
    /**
     * Determines the increment of minutes in the minute dropdown
     */
    timePickerIncrement: {
      type: Number,
      default: 5
    },
    /**
     * Use 24h format for the time
     */
    timePicker24Hour: {
      type: Boolean,
      default: false
    },
    /**
     * Allows you to select seconds except hour/minute
     */
    timePickerSeconds: {
      type: Boolean,
      default: false
    },
    /**
     * This is the v-model prop which the component uses.
     */
    dateRange: { // for v-model
      default: null,
      required: true
    },
    /**
     * You can set this to false in order to hide the ranges selection. Otherwise it is an object with key/value. See below
     * @default *see below
     */
    ranges: {
      type: [Object, Boolean],
      default () {
        return {
          Today: [moment(), moment()],
          Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'This Year': [moment().startOf('year'), moment().endOf('year')],
          'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }
      }
    },
    /**
     * You can set this to true in order to hide the Compare with Preview Metrics switch
     */
    noCompare: {
      type: Boolean,
      default: false
    },
    showCompareStrategies: {
      type: Boolean,
      default: false
    },
    /**
     * which way the picker opens - "center", "left" or "right"
     */
    opens: {
      type: String,
      default: 'center'
    },
    /**
     function(classes, date) - special prop type function which accepts 2 params:
     "classes" - the classes that the component's logic has defined,
     "date" - tha date currently processed.
     You should return Vue class object which should be applied to the date rendered.
     */
    dateFormat: Function,
    /**
     * *WIP*
     */
    alwaysShowCalendars: {
      type: Boolean,
      default: true
    },
    compareOverride: {
      type: Boolean,
      default: true
    },
    showClearButton: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    compareRange: {
      type: Object,
      default: () => ({
        start: undefined,
        end: undefined
      })
    },
    showShortcut: {
      type: Boolean,
      default: true
    },
    format: {
      type: String
    },
    customRangeKey: {
      type: String
    },
    /**
     * if user selects a day, have end date set to end of day hours intead of beginning of day hours
     * ie: May 28 23:59:58 vs May 28 00:00:00
     */
    endDateUseEndOfDay: {
      type: Boolean,
      default: false
    }
  },
  data () {
    const locale = {
      direction: 'ltr',
      format: this.$moment.localeData().longDateFormat('L'),
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: this.$moment.weekdaysMin(),
      monthNames: this.$moment.monthsShort(),
      firstDay: this.$moment.localeData().firstDayOfWeek()
    }

    // let data = { locale: localeData(this.localeData) }
    // if (this.dateRange) {
    //   let startDate = this.dateRange.startDate || null
    //   let endDate = this.dateRange.endDate || null
    //
    //   data.monthDate = startDate ? new Date(startDate) : new Date()
    //   data.nextMonthDate = nextMonth(data.monthDate)
    //   data.start = startDate ? new Date(startDate) : null
    //   if (this.singleDatePicker) {
    //     // ignore endDate for singleDatePicker
    //     data.end = data.start
    //   } else {
    //     data.end = endDate ? new Date(endDate) : null
    //   }
    // update day names order to firstDay
    if (locale.firstDay !== 0) {
      let iterator = locale.firstDay
      while (iterator > 0) {
        locale.daysOfWeek.push(locale.daysOfWeek.shift())
        iterator--
      }
    }
    return {
      open: false,
      in_selection: false,
      locale,
      previousSpan: {},
      previousSpanOverrides: {},
      start: undefined,
      end: undefined,
      compareStart: undefined,
      compareEnd: undefined,
      compareStartOverride: undefined,
      compareEndOverride: undefined,
      compare: false,
      priorDateStrategy: 'PREVIOUS',
      compareStrategies: [
        { text: 'Previous', value: 'PREVIOUS', description: 'Same period last month or last year.' },
        { text: 'Preceding', value: 'ROLLING', description: 'The period that immediately precedes the selected dates.' }
      ],
      shortcut: undefined,
      monthDate: undefined,
      nextMonthDate: undefined,
      customRangeCreate: false,
      customRangeEdit: '',
      customRange: {
        lastXDays: null,
        offset: null
      },
      savedRanges: {},
      rawSavedRanges: []
    }
  },
  unmounted () {
    this.clearOverridesrrides()
  },
  methods: {
    handleCompare (e) {
      if (e) {
        this.getPreviousSpan()
      } else {
        this.clearOverridesrrides()
      }
      this.compare = e
      this.focusPicker()
    },
    handleCompareStrategyChange (priorDateStrategy) {
      this.priorDateStrategy = priorDateStrategy
      this.getPreviousSpan()
    },
    manualEntry (type, dateString) {
      if (this.$moment(dateString).isValid()) {
        this[type] = this.$moment(dateString).toDate()
        this.shortcut = undefined
      } else {
        this.$toast('Invalid Date Format')
      }
    },
    labelCustom (value) {
      const [nothing, days, offset] = value.split(':')
      let name = `Last ${days} Days`
      if (offset > 0) {
        name += `, Offset ${offset} Days`
      }
      return name
    },
    manualCompareEntry (start, value) {
      start ? this.compareStartOverride = value : this.compareEndOverride = value
      if (this.compareStart === this.compareStartOverride && this.compareEnd === this.compareEndOverride) {
        this.compareStartOverride = undefined
        this.compareEndOverride = undefined
      }
      if (this.compareStartOverride && !this.compareEndOverride) {
        this.compareEndOverride = this.compareEnd
      }
      if (!this.compareStartOverride && this.compareEndOverride) {
        this.compareStartOverride = this.compareStart
      }
    },
    dateFormatFn (classes, date) {
      const dt = new Date(date)
      dt.setHours(0, 0, 0, 0)

      const start = new Date(this.start)
      start.setHours(0, 0, 0, 0)

      const end = new Date(this.end)
      end.setHours(0, 0, 0, 0)

      classes['in-range'] = dt >= start && dt <= end

      return this.dateFormat ? this.dateFormat(classes, date) : classes
    },
    changeLeftMonth (value) {
      const newDate = new Date(value.year, value.month, 1)

      this.monthDate = newDate
      if (this.linkedCalendars || (yearMonth(this.monthDate) >= yearMonth(this.nextMonthDate))) {
        this.nextMonthDate = validateDateRange(nextMonth(newDate), this.minDate, this.maxDate)
        if (yearMonth(this.monthDate) === yearMonth(this.nextMonthDate)) {
          this.monthDate = validateDateRange(prevMonth(this.monthDate), this.minDate, this.maxDate)
        }
      }
    },
    changeRightMonth (value) {
      const newDate = new Date(value.year, value.month, 1)
      this.nextMonthDate = newDate
      if (this.linkedCalendars || (yearMonth(this.nextMonthDate) <= yearMonth(this.monthDate))) {
        this.monthDate = validateDateRange(prevMonth(newDate), this.minDate, this.maxDate)
        if (yearMonth(this.monthDate) === yearMonth(this.nextMonthDate)) {
          this.nextMonthDate = validateDateRange(nextMonth(this.nextMonthDate), this.minDate, this.maxDate)
        }
      }
    },
    normalizeDatetime (value, oldValue, isEndDate) {
      if (!value) {
        return null
      }
      const newDate = new Date(value)
      if (isEndDate && this.endDateUseEndOfDay) {
        newDate.setHours(23)
        newDate.setMinutes(59)
        newDate.setSeconds(59)
        newDate.setMilliseconds(999)
      }
      if (this.timePicker && oldValue) {
        newDate.setHours(oldValue.getHours())
        newDate.setMinutes(oldValue.getMinutes())
        newDate.setSeconds(oldValue.getSeconds())
        newDate.setMilliseconds(oldValue.getMilliseconds())
      }

      return newDate
    },
    dateClick (value) {
      if (this.in_selection) {
        this.in_selection = false
        this.end = this.normalizeDatetime(value, this.end, true)

        if (this.end < this.start) {
          this.in_selection = true
          this.start = this.normalizeDatetime(value, this.start)
        }
        this.shortcut = undefined
      } else {
        this.start = this.normalizeDatetime(value, this.start)
        this.end = this.normalizeDatetime(value, this.end, true)
        if (!this.singleDatePicker) {
          this.in_selection = true
        }
      }
    },
    hoverDate (value) {
      if (this.singleDatePicker) return
      const dt = this.normalizeDatetime(value, this.end, true)
      if (this.in_selection && dt >= this.start) {
        this.end = dt
      }
    },
    togglePicker (value, event) {
      if (typeof value === 'boolean') {
        this.open = value
      } else {
        this.open = !this.open
      }
      if (this.open) {
        this.focusPicker()
      }

      if (event === true) {
        this.$emit('toggle', this.open, this.togglePicker)
      }
    },
    focusPicker () {
      this.$nextTick(() => {
        const elem = document.getElementById('date-range-picker-calendar-popup')
        if (elem) {
          elem.focus()
        }
      })
    },
    clickedApply () {
      // this.open = false
      this.open = false
      this.update()
      this.$emit('apply')
    },
    update () {
      this.$emit('update:dateRange', {
        start: this.start,
        end: this.end,
        shortcut: this.shortcut,
        compare: this.compare,
        compareStart: this.compareStart,
        compareEnd: this.compareEnd,
        compareStartOverride: this.compareStartOverride,
        compareEndOverride: this.compareEndOverride,
        priorDateStrategy: this.priorDateStrategy
      })
    },
    clearDates () {
      this.togglePicker(false, true)
      this.start = undefined
      this.end = undefined
      this.shortcut = undefined
      this.compare = false
      this.compareStart = undefined
      this.compareEnd = undefined
      this.compareStartOverride = undefined
      this.compareEndOverride = undefined
      this.update()
      this.$emit('apply')
    },
    clickAway (event) {
      const popover = document.getElementById('date-range-picker-calendar-popup')
      if (event.relatedTarget && popover && popover.contains(event.relatedTarget)) {
        return
      }
      if (this.open) {
        // reset start and end
        this.start = this.dateRange.start
        this.end = this.dateRange.end
        this.togglePicker(false, true)
      }
    },
    clickRange ({ range, shortcut }) {
      this.start = this.normalizeDatetime(range[0])
      this.end = this.normalizeDatetime(range[1])
      this.shortcut = shortcut
      this.monthDate = this.normalizeDatetime(range[0])
      this.nextMonthDate = nextMonth(this.monthDate)
    },
    onUpdateStartTime (value) {
      const start = new Date(this.start)
      start.setHours(value.hours)
      start.setMinutes(value.minutes)
      start.setSeconds(value.seconds)

      this.start = start
      this.focusPicker()
    },
    onUpdateEndTime (value) {
      const end = new Date(this.end)
      end.setHours(value.hours)
      end.setMinutes(value.minutes)
      end.setSeconds(value.seconds)

      this.end = end
      this.focusPicker()
    },
    clearOverridesrrides () {
      if (this.compareOverride) {
        this.compareStartOverride = undefined
        this.compareEndOverride = undefined
      }
    },
    async getPreviousSpan () {
      if (!this.compareOverride) {
        return false
      }
      const start = this.$moment(this.start).format('YYYY-MM-DDT00:00:00')
      const end = this.$moment(this.end).format('YYYY-MM-DDT23:59:59')
      const priorDateStrategy = this.priorDateStrategy === 'PREVIOUS' ? undefined : this.priorDateStrategy
      const result = await this.$res.fetch.getPreviousSpan(start, end, priorDateStrategy)
      if (result) {
        this.compareStart = this.$moment(result.priorStartDate).format(this.locale.format)
        this.compareEnd = this.$moment(result.priorEndDate).format(this.locale.format)
      }
    },
    friendlyDate (date) {
      const format = this.format
        ? this.format
        : this.timePicker
          ? 'MMM D, YYYY - h:mm A'
          : 'MMM D, YYYY'
      return this.$moment(date).format(format)
    },
    slashDate (date) {
      const format = this.timePicker ? 'MM/DD/YYYY h:mm A' : 'MM/DD/YYYY'
      return this.$moment(date).format(format)
    },
    async loadSavedRanges () {
      if (this.customRangeKey) {
        const res = await this.$res.fetch.userPreference('DATE_RANGE', this.customRangeKey)
        if (res) {
          this.rawSavedRanges = JSON.parse(res.preferenceValue)
          this.savedRanges = this.rawSavedRanges.reduce((accu, curr) => ({
            ...accu,
            ...this.createRangeFromPref(curr)
          }), {})
        }
      }
    },
    async createCustomRange (modify) {
      const dbFormat = `CUSTOM:${this.customRange.lastXDays}:${this.customRange.offset || 0}`
      const raw = [...this.rawSavedRanges]
      if (modify) {
        const i = raw.indexOf(this.customRangeEdit)
        if (i >= 0) {
          raw[i] = dbFormat
        }
      } else {
        raw.push(dbFormat)
      }
      const res = await this.$res.set.userPreference({ type: 'DATE_RANGE', key: this.customRangeKey, value: encodeURIComponent(JSON.stringify(raw)) })
      if (res) {
        this.rawSavedRanges = raw
        this.savedRanges = {
          ...this.savedRanges,
          ...this.createRangeFromPref(dbFormat)
        }
        if (!modify) {
          if (this.$refs.calendarRanges) {
            this.$refs.calendarRanges.scrollTop = this.$refs.calendarRanges.scrollHeight
          }
          this.clickRange({ range: this.savedRanges[dbFormat], shortcut: dbFormat })
        } else {
          delete this.savedRanges[this.customRangeEdit]
        }
        this.cancelCustomRange()
      }
    },
    async deleteCustomRange (key) {
      const i = this.rawSavedRanges.indexOf(key)
      if (i >= 0) {
        this.rawSavedRanges.splice(i, 1)
        const res = await this.$res.set.userPreference({ type: 'DATE_RANGE', key: this.customRangeKey, value: encodeURIComponent(JSON.stringify(this.rawSavedRanges)) })
        if (res) {
          delete this.savedRanges[key]
          this.cancelCustomRange()
        }
      }
    },
    editCustomRange (key) {
      let [nothing, days, offset] = key.split(':')
      if (offset === 0) {
        offset = null
      }
      this.customRange.lastXDays = days
      this.customRange.offset = offset
      this.customRangeCreate = true
      this.customRangeEdit = key
    },
    cancelCustomRange () {
      this.customRangeCreate = false
      this.customRangeEdit = false
      this.customRange.lastXDays = null
      this.customRange.offset = null
    },
    createRangeFromPref: dateShortcuts.createRangeFromPref
  },
  computed: {
    startAndEnd () {
      const start = this.$moment(this.start).format(this.locale.format)
      const end = this.$moment(this.end).format(this.locale.format)

      return { start, end }
    },
    startText () {
      return (this.start === null) ? '' : this.$moment(this.start).format(this.locale.format)
    },
    endText () {
      return (this.end === null) ? '' : this.$moment(new Date(this.end)).format(this.locale.format)
    },
    min () {
      return this.minDate ? new Date(this.minDate) : null
    },
    max () {
      return this.maxDate ? new Date(this.maxDate) : null
    },
    pickerStyles () {
      return {
        'show-calendar': this.open,
        'show-ranges': !!this.ranges,
        'show-weeknumbers': this.showWeekNumbers,
        single: this.singleDatePicker,
        opensright: this.opens === 'right',
        opensleft: this.opens === 'left',
        openscenter: this.opens === 'center',
        linked: this.linkedCalendars
      }
    },
    combinedRanges () {
      return { ...this.ranges, ...this.savedRanges }
    },
    customRangePreview () {
      if (this.customRange.lastXDays === null) return ''
      const shortcut = `CUSTOM:${this.customRange.lastXDays}:${this.customRange.offset || 0}`
      const range = this.createRangeFromPref(shortcut)
      return range[shortcut].map(d => this.slashDate(d)).join(' - ')
    },
    customRangeLabelPreview () {
      if (this.customRange.lastXDays === null) return ''
      let name = `Last ${this.customRange.lastXDays} Days`
      if (this.customRange.offset !== null && this.customRange.offset > 0) {
        name += `, Offset ${this.customRange.offset} Days`
      }
      return name
    }
  },
  watch: {
    dateRange: {
      immediate: true,
      deep: true,
      handler (value) {
        this.start = value.start?.toDate ? value.start.toDate() : value.start
        this.end = value.end?.toDate ? value.end.toDate() : value.end
        this.compareStart = value.compareStart
        this.compareEnd = value.compareEnd
        this.compareStartOverride = value.compareStartOverride
        this.compareEndOverride = value.compareEndOverride
        this.shortcut = value.shortcut
        this.compare = value.compare
        this.monthDate = value.start ? new Date(value.start) : new Date()
        this.nextMonthDate = nextMonth(this.monthDate)
        if (value.priorDateStrategy) {
          this.priorDateStrategy = value.priorDateStrategy
        }
      }
    },
    startAndEnd: {
      handler: function (newVal, oldVal) {
        if (this.compare && !_isEqual(newVal, oldVal)) {
          this.getPreviousSpan()
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.loadSavedRanges()
  }
}

</script>

<style lang="scss">
@import '../assets/daterangepicker.css';
</style>

<style lang="scss" scoped>
$week-width: 682px - 628px;

.reportrange-text {
  background: #fff;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid #ccc;
  width: 100%;
}

.daterangepicker {
  flex-direction: column;
  display: flex;
  width: auto;

  @media screen and (max-width: 768px) {
    &.show-ranges {
      .drp-calendar.left {
        border-left: 0px;
      }

      .ranges {
        border-bottom: 1px solid #ddd;
      }
    }
  }

  &.single {
    @media screen and (max-width: 340px) {
      min-width: 250px;

      &.show-weeknumbers {
        min-width: 250px + $week-width;
      }
    }
  }

  &.show-calendar {
    display: block;
  }
}

.daterangepicker {
  &.opensleft {
    top: 35px;
    right: 10px;
    left: auto;
  }

  &.openscenter {
    top: 35px;
    right: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }

  &.opensright {
    top: 35px;
    left: 10px;
    right: auto;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .2s ease;
}

.slide-fade-leave-active {
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active for <2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.custom-metrics-date-input {
  width: 85px;
}

</style>
<style lang="scss">
.vue-daterange-picker {
  @media screen and (max-width: 768px) {
    &.show-ranges {
      .ranges {
        ul {
          display: flex;
          flex-wrap: wrap;
          width: auto;
        }
      }
    }
  }
}
</style>
